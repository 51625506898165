import React from 'react';
import { Checkbox } from 'antd';

import './checkbox-multiselect.css';

type CheckboxMultiSelectionProps = {
  label?: string;
  isChecked: boolean;
  isMixedSelection?: boolean;
  setSelected: (e: any) => void;
};

export const CheckboxMultiSelect: React.FC<CheckboxMultiSelectionProps> = ({
  label,
  isChecked,
  isMixedSelection = false,
  setSelected,
}) => {
  return (
    <div
      data-testid="checkbox-multiselect"
      className="checkbox-file-selection checkbox-multiselect"
    >
      <Checkbox
        indeterminate={isMixedSelection}
        disabled={false}
        checked={isChecked}
        onChange={(e) => {
          setSelected(e);
        }}
      />
      {label && <div className="header">{label}</div>}
    </div>
  );
};
