import { Store } from 'pullstate';
import { ConversationsApi } from 'aida-api-client';
import ApiService from 'services/api';
import { logger } from 'services/logs/logger';
import {
  setIsMessagesWindowScrolling,
  setIsSidebarCollapsed,
} from './app-context';
import { loadUserLimits } from './user';
import { sleep } from 'utils/async-utils';
import {
  FileType,
  ConversationFileType,
  ConversationIndexType,
  sanitizeFileContexts,
  filterValidOnlyFileContexts,
  getUniqueSelectedFileContextIdsFromMessages,
  IndexType,
} from 'utils/file-utils';

let apiConfig: any = null;
let api: ConversationsApi | null = null;

export type MessageItemType = {
  messageId: string;
  messageType: string;
  message: string;
  profileImg: string;
  datetimeCreated?: string;
  datetimeLastUpdated?: string;
  generationStatus?: string;
  userFeedback?: any;
  previousMessageId?: string;
  allowRetry?: boolean;
  associatedFileRecordIds?: any[];
  associatedIndexIds?: any[];
};

export type CurrentConversationType = {
  hasLoaded: boolean;
  isLoading: boolean;
  messages: MessageItemType[];
  gptModel: string;
  id?: string;
  fileContexts: string[]; // File IDs that have been selected
  fileContextFiles: ConversationFileType[]; // All files that are available with isSelected flag
  fileStats: {
    count: number; // Total number of files used in the conversation
    valid: number; // Number of files that are not errored
  };
  indexContexts: string[]; // Index IDs that have been selected
  indexContextIndexes: ConversationIndexType[]; // All indexes that are available with isSelected flag
  isStreaming: boolean;
};

const initialState: CurrentConversationType = {
  hasLoaded: false,
  isLoading: false,
  id: undefined,
  messages: [],
  gptModel: '',
  fileContexts: [],
  fileContextFiles: [],
  fileStats: {
    count: 0,
    valid: 0,
  },
  indexContexts: [],
  indexContextIndexes: [],
  isStreaming: false,
};

export const CurrentConversationStore = new Store(initialState);

CurrentConversationStore.createReaction(
  (s) => s.messages,
  (messages, draft) => {
    if (
      messages.length > 0 &&
      messages[messages.length - 1].messageType === 'userInput' &&
      api &&
      draft.id
    ) {
      draft.messages = [
        ...messages,
        {
          message: '...',
          generationStatus: 'loading',
          messageId: 'NewMessage_' + (messages.length + 1).toString(),
          profileImg: '/aida-profile.svg',
          messageType: 'generatedResponse',
          associatedFileRecordIds: [...draft.fileContexts],
          associatedIndexIds: [...draft.indexContexts],
        },
      ];
      draft.isStreaming = true;
      const message = messages[messages.length - 1];
      if (message.messageType === 'userInput') {
        sendMessageToApi(
          message,
          draft.id,
          draft.gptModel,
          draft.fileContexts,
          draft.indexContexts,
        );
      }
    }
  },
);

const VALID_FILE_CONTEXT_STATUSES = ['done', 'deleted'];
CurrentConversationStore.createReaction(
  (s) => [s.fileContexts, s.fileContextFiles, s.indexContexts],
  ([fileContexts, fileContextFiles, indexContexts], draft) => {
    let fileCount = 0;
    let validFiles: FileType[] = [];

    if (fileContexts.length) {
      validFiles = filterValidOnlyFileContexts(fileContextFiles, fileContexts);
      const filesUsed = [...fileContextFiles]
        .filter((s) => (fileContexts as string[]).includes((s as FileType).id))
        .filter((f) =>
          VALID_FILE_CONTEXT_STATUSES.includes(
            (f as FileType).processingStatus,
          ),
        );
      fileCount = filesUsed.length;
    }

    const indexCount = indexContexts.length;

    draft.fileStats = {
      count: fileCount + indexCount,
      valid: validFiles.length + indexCount,
    };
  },
);

export const setFileContextFiles = (files: FileType[]) => {
  CurrentConversationStore.update((s) => {
    return {
      ...s,
      fileContextFiles: files.map((f) => {
        const newf = f as ConversationFileType;
        if (s.fileContexts.includes(f.id)) {
          newf.isSelected = true;
        }
        return newf;
      }),
    };
  });
};
export const setIndexContextIndexes = (indexes: IndexType[]) => {
  CurrentConversationStore.update((s) => {
    return {
      ...s,
      indexContextIndexes: indexes.map((i) => {
        const newI = i as ConversationIndexType;
        if (s.indexContexts.includes(i.id)) {
          newI.isSelected = true;
        }
        return newI;
      }),
    };
  });
};

const getErrorMessage = (messageId) => {
  return {
    message: 'Unable to generate response',
    messageId: messageId,
    profileImg: '/aida-profile.svg',
    generationStatus: 'error',
    messageType: 'generatedResponse',
    allowRetry: false,
  };
};

const setErrorState = (messageId) => {
  CurrentConversationStore.update((s) => {
    return {
      ...s,
      messages: [
        ...s.messages.slice(0, s.messages.length - 1),
        getErrorMessage(messageId),
      ],
    };
  });
};

const mapMessage = (m) => {
  if (m.messageType !== 'userInput' && !m.textContent) {
    return getErrorMessage(m.id);
  }

  return {
    ...m,
    messageId: m.id,
    messageType: m.messageType,
    message: m.textContent || '',
    userFeedback: m.userFeedback,
    profileImg:
      m.messageType === 'userInput' ? '/profile.svg' : '/aida-profile.svg',
  };
};

let streamController;
const streamNewMessage = async (conversationId, messageId, messageType) => {
  if (streamController) {
    streamController.abort();
  }

  streamController = new AbortController();

  let reader;
  try {
    reader = await ApiService.streamMessage(conversationId, messageId);
  } catch (e) {
    if ((e as any).name === 'AbortError') {
      logger.error('Streaming operation was cancelled');
      return;
    }

    logger.error('Unable to stream message');
    setErrorState(messageId);
    return;
  }

  const decoder = new TextDecoder('utf-8');
  let result = '';

  while (reader && !streamController.signal.aborted) {
    const { done, value } = await reader.read();

    if (done) {
      CurrentConversationStore.update((s) => {
        const lastMessage = s.messages[s.messages.length - 1];
        return {
          ...s,
          isStreaming: false,
          messages: [
            ...s.messages.slice(0, s.messages.length - 1),
            {
              ...lastMessage,
              generationStatus: 'done',
            },
          ],
        };
      });
      loadUserLimits(); // Update the user limits after submission
      break;
    }

    result += decoder.decode(value);
    const { datetimeLastUpdated } = await ApiService.getMessageById(
      conversationId,
      messageId,
    );

    // eslint-disable-next-line no-loop-func
    CurrentConversationStore.update((s) => {
      return {
        ...s,
        messages: [
          ...s.messages.slice(0, s.messages.length - 1),
          {
            message: result,
            messageId: messageId,
            profileImg: '/aida-profile.svg',
            generationStatus: 'pending',
            messageType: messageType,
            datetimeLastUpdated: datetimeLastUpdated,
          },
        ],
      };
    });
  }
};

const sendMessageToApi = async (
  message: any,
  conversationId: string,
  gptModel: string,
  associatedFileRecordIds?: string[],
  associatedIndexIds?: string[],
) => {
  if (!api) {
    return;
  }

  const currentFiles = CurrentConversationStore.getRawState().fileContextFiles;
  const contextIds = sanitizeFileContexts(
    currentFiles,
    associatedFileRecordIds,
  );

  try {
    const response = await ApiService.sendMessage(conversationId, {
      model: gptModel,
      textContent: message.message,
      associatedFileRecordIds: contextIds,
      associatedIndexIds: associatedIndexIds,
    });

    if (response) {
      const message = response[(response as any).length - 1];
      streamNewMessage(conversationId, message.id, message.messageType);
    }
  } catch (e) {
    logger.error('Unable to send message to API');
    setErrorState(message.messageId);
  }
};

const getMessagesByConversationId = async (conversationId: string) => {
  if (!api) {
    return;
  }

  setIsSidebarCollapsed(true, 'lg');
  const messagesResult =
    await ApiService.getConversationMessagesById(conversationId);

  if (!messagesResult || messagesResult.length === 0) {
    return [];
  }

  const sortedMessages = [...messagesResult]
    .sort((a, b) => {
      if (!a.datetimeCreated || !b.datetimeCreated) {
        return -1;
      }
      if (a.datetimeCreated === b.datetimeCreated) {
        return a.messageType === 'userInput' ? -1 : 1;
      }
      let dateA = new Date(a.datetimeCreated),
        dateB = new Date(b.datetimeCreated);
      return (dateA as any) - (dateB as any);
    })
    .map(mapMessage);

  return sortedMessages;
};

export const deleteConversation = async (conversationId: string) => {
  if (!api) {
    return;
  }
  return ApiService.deleteConversationById(conversationId);
};

export const setApiConfig = async (config: any) => {
  apiConfig = config;
  if (apiConfig) {
    const token = await ApiService.acquireFreshToken();
    api = new ConversationsApi({
      ...apiConfig,
      apiKey: token,
    });
  }
};

export const setGptModel = (gptModel: string) => {
  CurrentConversationStore.update((s) => {
    return {
      ...s,
      gptModel: gptModel,
    };
  });
};

export const setCurrentConversation = async (conversationId?: string) => {
  setIsMessagesWindowScrolling(false);

  await CurrentConversationStore.update((s) => {
    return {
      ...s,
      id: conversationId,
      hasLoaded: !conversationId,
      isLoading: !!conversationId,
      messages: [],
    };
  });
  setIsSidebarCollapsed(true, 'lg');

  if (streamController) {
    await streamController.abort();
    await sleep(200); // Give the stream time to close
  }

  if (!conversationId) {
    return CurrentConversationStore.update((s) => {
      return {
        ...initialState,
        fileContextFiles: s.fileContextFiles,
        indexContextIndexes: s.indexContextIndexes,
        gptModel: s.gptModel,
      };
    });
  }

  try {
    const conversationResponse =
      await ApiService.getConversationById(conversationId);
    const id = conversationResponse.id;

    const messages = await getMessagesByConversationId(id);

    return CurrentConversationStore.update((s) => {
      return {
        ...s,
        id: id,
        hasLoaded: true,
        isLoading: false,
        messages: [...(messages as any)],
        fileContexts: getUniqueSelectedFileContextIdsFromMessages(
          messages as string[],
        ),
        indexContexts: getUniqueSelectedFileContextIdsFromMessages(
          messages as string[],
          'associatedIndexIds',
        ),
      };
    });
  } catch (e) {
    logger.error('Unable to load conversation');
    setConversationLoading(false);
  }
};

export const resubmitLastMessage = async (errorMessageId) => {
  const errorMessage = CurrentConversationStore.getRawState().messages.find(
    (m) => m.messageId === errorMessageId,
  );
  if (!errorMessage) {
    return;
  }

  const messageToResubmit =
    CurrentConversationStore.getRawState().messages.find(
      (m) => m.messageId === errorMessage.previousMessageId,
    );

  if (!messageToResubmit) {
    return;
  }

  const conversationId = CurrentConversationStore.getRawState().id;
  if (!conversationId) {
    return;
  }

  await ApiService.updateConversationMessageById(
    conversationId,
    messageToResubmit.messageId,
    messageToResubmit?.datetimeLastUpdated || '',
    {
      ...messageToResubmit,
    },
  );
};

export const sendMessage = async (message: string) => {
  if (!api) {
    return;
  }

  let currentConversationId = CurrentConversationStore.getRawState().id;
  if (!currentConversationId) {
    try {
      const result = await ApiService.getNewConversation();
      currentConversationId = result?.id;
      const lastModifiedDate = result?.datetimeLastUpdated;

      if (!currentConversationId) {
        return;
      }

      await ApiService.updateConversationById(
        currentConversationId,
        lastModifiedDate,
        {
          conversationName: message,
        },
      );
    } catch (e) {
      logger.error('Unable to send message');
    }
  }

  return CurrentConversationStore.update((s) => {
    const currentMessages = [...s.messages];
    return {
      ...s,
      hasLoaded: true,
      id: currentConversationId || 1,
      messages: [
        ...currentMessages,
        {
          message: message,
          messageId: s.messages.length,
          profileImg: '/profile.svg',
          messageType: 'userInput',
          associatedFileRecordIds: [...s.fileContexts],
          associatedIndexIds: [...s.indexContexts],
        },
      ],
    };
  });
};

export const renameConversation = async (
  conversationId: string,
  name: string,
) => {
  if (!api) {
    return;
  }

  const updatedConversation =
    await ApiService.getConversationById(conversationId);

  await ApiService.updateConversationById(
    conversationId,
    updatedConversation.datetimeLastUpdated,
    {
      conversationName: name,
    },
  );
};

export const appendToFileContexts = (fileContexts: string[]) => {
  CurrentConversationStore.update((s) => {
    return {
      ...s,
      fileContexts: [...s.fileContexts, ...fileContexts],
    };
  });
};

export const setFileContexts = (newFileContexts: any[]) => {
  CurrentConversationStore.update((s) => {
    return {
      ...s,
      fileContexts: [...s.fileContexts, ...newFileContexts],
    };
  });
};

export const setIndexContexts = (newIndexContexts: any[]) => {
  CurrentConversationStore.update((s) => {
    return {
      ...s,
      indexContexts: [...s.indexContexts, ...newIndexContexts],
    };
  });
};

export const setConversationLoading = (isLoading: boolean) => {
  CurrentConversationStore.update((s) => {
    return {
      ...s,
      hasLoaded: !isLoading,
      isLoading,
      messages: [],
    };
  });
};

export const removeFileFromContexts = (fileContext: string) => {
  const currentConversationContexts =
    CurrentConversationStore.getRawState().fileContexts;
  const updatedContexts = currentConversationContexts.filter(
    (context) => context !== fileContext,
  );

  CurrentConversationStore.update((s) => {
    return {
      ...s,
      fileContexts: updatedContexts,
    };
  });
};

export const removeIndexFromContexts = (fileContext: string) => {
  const currentConversationContexts =
    CurrentConversationStore.getRawState().indexContexts;
  const updatedContexts = currentConversationContexts.filter(
    (context) => context !== fileContext,
  );

  CurrentConversationStore.update((s) => {
    return {
      ...s,
      indexContexts: updatedContexts,
    };
  });
};

export const resetStore = () => {
  CurrentConversationStore.update((s) => {
    return {
      ...initialState,
      fileContextFiles: s.fileContextFiles,
      setIndexContextIndexes: s.indexContextIndexes,
      gptModel: s.gptModel,
    };
  });
};
