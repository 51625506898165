import { format } from 'date-fns';

export function stringifyIdleConfirmationValue(secondsLeft: number) {
  if (secondsLeft < 60) {
    return `${secondsLeft} sec`;
  }

  if (secondsLeft < 60 * 60) {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;
    return `${minutes} min ${seconds < 10 ? '0' : ''}${seconds} sec`;
  }

  return 'about an hour';
}

export function formatDate(date: string) {
  if (date === null || date === undefined || date === '') {
    return '';
  }
  return format(new Date(date), 'yyyy/MM/dd');
}
