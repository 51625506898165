import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Button, ResourceList } from 'ui';
import { Segment, Segments } from 'ui/molecules/SegmentedControl';
import { sortArrayByProperty } from 'utils/sort-methods';
import { ResourceSelectionType } from 'utils/file-utils';

import { ReactComponent as DeleteIcon } from './delete.svg';
import './file-selector.css';

export const setAllSelectedItems = ({
  items,
  resourceList,
  setSelectedFiles,
  setSelectedIndexes,
}) => {
  const filesIdsToAdd = [] as string[];
  const indexIdsToAdd = [] as string[];
  for (const item of items) {
    const fullItem = resourceList.find(
      (r: ResourceSelectionType) => r.id === item,
    );
    if (fullItem.itemType === 'index') {
      indexIdsToAdd.push(item);
    } else {
      filesIdsToAdd.push(item);
    }
  }
  setSelectedFiles(filesIdsToAdd);
  setSelectedIndexes(indexIdsToAdd);
};

const FileSelector = ({
  resourceList,
  selectedFileIds,
  selectedIndexIds,
  currentConversationSelectedFileIds,
  setSelectedFiles,
  setSelectedIndexes,
  setConfirmDelete,
}) => {
  const [error, setError] = useState<string>('');
  const [segments, setSegments] = useState<Segment[] | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [
    currentConversationSelectedFiles,
    setCurrentConversationSelectedFiles,
  ] = useState<any[]>([]);

  useEffect(() => {
    setCurrentConversationSelectedFiles(
      resourceList.filter(
        (resource: ResourceSelectionType) =>
          currentConversationSelectedFileIds.includes(resource.id) &&
          (resource.processingStatus === 'done' ||
            resource.processingStatus === 'deleted'),
      ),
    );
  }, [resourceList, currentConversationSelectedFileIds]);

  useEffect(() => {
    setSegments([
      {
        label: 'All',
        index: 0,
        active: currentIndex === 0,
        children: (
          <ResourceList
            availableToSelectItems={resourceList
              .filter(
                (r: ResourceSelectionType) =>
                  !currentConversationSelectedFileIds.includes(r.id),
              )
              .sort((a, b) => sortArrayByProperty(a, b, 'displayFileName'))}
            setSelectedItems={(items) =>
              setAllSelectedItems({
                items,
                resourceList,
                setSelectedFiles,
                setSelectedIndexes,
              })
            }
            selectedItemIds={[...selectedFileIds, ...selectedIndexIds]}
            setError={function (error: string): void {
              setError(error);
            }}
          />
        ),
      },
      {
        label: 'Files',
        active: currentIndex === 1,
        index: 1,
        children: (
          <ResourceList
            availableToSelectItems={resourceList
              .filter((r) => !currentConversationSelectedFileIds.includes(r.id))
              .filter((r) => r.itemType === 'file')
              .sort((a, b) => sortArrayByProperty(a, b, 'displayFileName'))}
            setSelectedItems={function (items: any[]): void {
              setSelectedFiles(items);
            }}
            selectedItemIds={selectedFileIds}
            setError={function (error: string): void {
              setError(error);
            }}
          />
        ),
      },
      {
        label: 'Indexes',
        active: currentIndex === 2,
        index: 2,
        children: (
          <ResourceList
            availableToSelectItems={resourceList
              .filter((r) => !currentConversationSelectedFileIds.includes(r.id))
              .filter((r) => r.itemType === 'index')
              .sort((a, b) => sortArrayByProperty(a, b, 'displayFileName'))}
            setSelectedItems={function (items: any[]): void {
              setSelectedIndexes(items);
            }}
            selectedItemIds={selectedIndexIds}
            setError={function (error: string): void {
              setError(error);
            }}
          />
        ),
      },
      {
        label: 'Selected',
        active: currentIndex === 3,
        index: 3,
        children: (
          <ResourceList
            type="selected"
            availableToSelectItems={resourceList.filter((r) =>
              currentConversationSelectedFileIds.includes(r.id),
            )}
            setSelectedItems={function (): void {
              throw new Error('Function not implemented.');
            }}
            selectedItemIds={resourceList
              .filter((r) => r.isSelected)
              .map((r) => r.id)}
            setError={function (): void {
              throw new Error('Function not implemented.');
            }}
            title=""
            className="files-applied"
            showSelectedAll={false}
            hideCheckbox={true}
          />
        ),
      },
    ]);
  }, [
    currentConversationSelectedFileIds,
    currentConversationSelectedFiles,
    currentIndex,
    resourceList,
    selectedFileIds,
    selectedIndexIds,
    setSelectedFiles,
    setSelectedIndexes,
  ]);

  return (
    <div
      className={classNames('file-selector', {
        'has-files-applied': currentConversationSelectedFiles.length > 0,
      })}
      data-testid="test-file-selector"
    >
      {error && <div className="error">{error}</div>}
      {segments && (
        <Segments
          segments={segments}
          toggleActive={(label) => {
            const newIndex = segments.findIndex(
              (segment) => segment.label === label,
            );
            setCurrentIndex(newIndex);
          }}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
        />
      )}

      <div className="file-list-active-wrapper">
        {segments?.find((s) => s.active)?.children}
      </div>

      {resourceList.length > 0 && (
        <Button
          type="link"
          aria-label="Delete File(s)"
          data-testid="delete-file-button"
          className="ant-modal-round-button delete-button"
          icon={<DeleteIcon />}
          onClick={() => {
            if (selectedFileIds.length > 0) {
              setConfirmDelete(true);
            }
          }}
        />
      )}
    </div>
  );
};

export default FileSelector;
