import React from 'react';

import './empty-resource.css';

type EmptyResourceProps = {
  message?: string;
};

export const EmptyResource = ({ message = '' }: EmptyResourceProps) => {
  let messageToDisplay = 'No resources available';

  if (message.length) {
    messageToDisplay = message;
  }

  return (
    <div data-testid="empty-resource" className="empty-resource">
      {messageToDisplay}
    </div>
  );
};
