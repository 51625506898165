import { useState } from 'react';
import classNames from 'classnames';

import './segmented-control.css';

export type Segment = {
  label: string;
  children?: any;
  callback?: any;
  active?: boolean;
  index?: number;
};

export type SegmentedControlProps = {
  segments: Segment[];
  defaultSegment?: string;
  cssClass?: string;
};

export const SegmentedControl = ({
  segments,
  defaultSegment,
  cssClass = '',
}: SegmentedControlProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSegments, setCurrentSegments] = useState(() => {
    const hasDefaultSegment = segments.some((s) => s.label === defaultSegment);

    segments.forEach((segment, index) => {
      segment.active = false;
      segment.index = index;

      if (hasDefaultSegment && segment.label === defaultSegment) {
        segment.active = true;
        setCurrentIndex(index);
      }

      if (!hasDefaultSegment && index === 0) {
        segment.active = true;
        setCurrentIndex(index);
      }
    });
    return segments;
  });

  if (!segments.length) {
    return <></>;
  }

  const toggleActiveSegment = (label: string) => {
    setCurrentSegments((prev: Segment[]) =>
      prev.map((segment) => {
        if (segment.label === label) {
          return { ...segment, active: true };
        }

        if (segment.active) {
          return { ...segment, active: !segment.active };
        }

        return { ...segment, active: false };
      }),
    );
  };

  return (
    <div
      data-testid="segmented-control-container"
      className={`segmented-control-container ${cssClass}`}
    >
      <Segments
        segments={currentSegments}
        toggleActive={toggleActiveSegment}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      <SegmentContent segments={currentSegments} />
    </div>
  );
};

export const Segments = ({
  segments,
  toggleActive,
  setCurrentIndex,
  currentIndex,
}) => {
  const [slideDirection, setSlideDirection] = useState('left');

  return (
    <div className="segment-bar" data-testid="segment-bar">
      {segments.map((segment: Segment) => {
        const { active, index = 1, label, callback } = segment;

        return (
          <button
            key={label}
            data-testid="segment-button"
            className={classNames([
              'segment',
              {
                active,
                slideLeft: slideDirection === 'left',
                slideRight: slideDirection === 'right',
              },
            ])}
            onClick={() => {
              toggleActive(label);
              setCurrentIndex(index);

              if (index > currentIndex) {
                setSlideDirection('left');
              }

              if (index < currentIndex) {
                setSlideDirection('right');
              }

              if (callback) {
                callback();
              }
            }}
          >
            <span className="segment-label">{label}</span>
          </button>
        );
      })}
    </div>
  );
};

const SegmentContent = ({ segments }) => {
  const activeTab = segments.find((segment: Segment) => segment.active);

  return (
    <div className="segment-content">
      {activeTab ? activeTab.children : null}
    </div>
  );
};
