import { useCallback, useMemo } from 'react';
import { Configuration } from 'aida-api-client';
import useSilentRequest from './useSilentRequest';
import config from 'config';
import { useMsal } from '@azure/msal-react';
import { setAuthInstance, updateAccessToken } from 'stores/auth';

function useAidaApi() {
  const silentRequest = useSilentRequest();

  const basePath = config.aidaBasePath;

  const { instance } = useMsal();

  setAuthInstance(instance);

  const createApiConfig = useCallback(() => {
    return instance
      .acquireTokenSilent(silentRequest)
      .then((response) => {
        updateAccessToken(response.accessToken);
        return `Bearer ${response.accessToken}`;
      })
      .then((apiKey) => {
        return new Configuration({
          basePath,
          apiKey,
        });
      })
      .catch(async (error) => {
        if (error.name === 'InteractionRequiredAuthError') {
          await instance.acquireTokenRedirect(silentRequest);
        }
      });
  }, [instance, silentRequest, basePath]);

  return useMemo(() => ({ createApiConfig }), [createApiConfig]);
}

export default useAidaApi;
