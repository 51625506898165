import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import classNames from 'classnames';
import { returnThemeIfSystem } from 'ui/organisms/Settings/settings-menu-modal';

import ProtectedRoute from './pages/ProtectedRoute';
import Login from './pages/login';
import Home from './pages/home';
import Terms from './pages/terms';
import Splash from './pages/splash';
import ErrorPage from './pages/error';
import Report from './pages/report';
import { PermanentToastPage } from 'ui';

import {
  GlobalMessageStore,
  clearGlobalMessage,
} from './stores/global-message';
import useGlobalNotification from './services/hooks/useGlobalNotification';

import { AppContextStore } from './stores/app-context';
import { initializeFeatures, Features } from 'stores/features';
import { LocalStorageKey } from 'utils/localstorage';

import './App.css';

const router = createBrowserRouter([
  {
    path: '/splash',
    element: <Splash />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/signedout',
    element: (
      <PermanentToastPage message="You have been successfully signed out.">
        <Login />
      </PermanentToastPage>
    ),
  },
  {
    path: '/terms',
    element: (
      <ProtectedRoute>
        <Terms />
      </ProtectedRoute>
    ),
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
  },
  {
    path: '/report/bug',
    element: <Report formType="bug" />,
  },
  {
    path: '/report/feedback',
    element: <Report formType="feedback" />,
  },
]);

function App() {
  const globalMessage: any = GlobalMessageStore.useState((s) => s);
  const { globalMessageType, globalMessageText } = GlobalMessageStore.useState(
    (s) => {
      return { globalMessageType: s.type, globalMessageText: s.message };
    },
  );
  const { openNotification, notificationContextHolder } =
    useGlobalNotification();
  const currentTheme = AppContextStore.useState((s) => s.theme);
  const isHaltAndCatchFire = AppContextStore.useState(
    (s) => s.isHaltAndCatchFire,
  );

  useEffect(() => {
    const hasPreviousTheme = localStorage.getItem(LocalStorageKey.theme);

    if (!hasPreviousTheme) {
      return;
    }

    AppContextStore.update((_) => {
      return {
        theme: returnThemeIfSystem(hasPreviousTheme),
      };
    });

    initializeFeatures({
      [Features.IdleTimeout]: true,
    });
  }, []);

  useEffect(() => {
    if (
      globalMessageText &&
      (globalMessageType === 'error' ||
        globalMessageType === 'success' ||
        globalMessageType === 'permanent')
    ) {
      const additionalProps =
        globalMessageType === 'permanent' ? { duration: 0 } : {};

      setTimeout(() => {
        openNotification({
          ...additionalProps,
          message: globalMessage.message,
          type: globalMessageType === 'error' ? 'warning' : 'normal',
        });
        clearGlobalMessage();
      }, 200);
    }
  }, [globalMessage, globalMessageText, globalMessageType, openNotification]);

  return (
    <div className={classNames(['App', { [`-theme-${currentTheme}`]: true }])}>
      {!isHaltAndCatchFire && <RouterProvider router={router} />}
      {isHaltAndCatchFire && <ErrorPage />}
      <div id="modal-root"></div>

      {notificationContextHolder}
    </div>
  );
}

export default App;
