import { useState } from 'react';
import { Spin, Progress } from 'antd';
import classNames from 'classnames';
import { removeFileFromContexts } from 'stores/current-conversation';
import {
  FileType,
  formatFileErrorMessages,
  formatFileSize,
  formatFileTypeExtensions,
} from 'utils/file-utils';

import { ReactComponent as FileChitIconClose } from './file-chit-icons/icon-close.svg';
import { ReactComponent as FileChitIconPDF } from './file-chit-icons/icon-pdf.svg';
import { ReactComponent as FileChitIconPPTX } from './file-chit-icons/icon-pptx.svg';
import { ReactComponent as FileChitIconDOCX } from './file-chit-icons/icon-docx.svg';
import { ReactComponent as FileChitIconTXT } from './file-chit-icons/icon-txt.svg';
import { ReactComponent as FileChitIconXLSX } from './file-chit-icons/icon-xlsx.svg';
import { ReactComponent as FileChitIconIndex } from './file-chit-icons/icon-index.svg';

import './file-chit.css';

export const FileChit = ({ file, isEditable }) => {
  const fileIsEditable = isEditable;
  const fileIsProcessing = file.processingStatus === 'in progress';
  const fileIsUploading = file.processingStatus === 'uploading';
  const fileIsDeleted = file.processingStatus === 'deleted';
  const fileHasError =
    file.processingStatus === 'error' || file.processingFailureComment;
  const [isRemoving, setIsRemoving] = useState(false);

  const [fileType, extension] = formatFileTypeExtensions(file.displayFileName);

  const getStatusLabel = (fileType: string) => {
    if (file?.processingFailureComment) {
      return formatFileErrorMessages(file.processingFailureComment);
    }

    if (fileIsDeleted) {
      return 'File deleted';
    }

    if (fileIsProcessing) {
      return 'Processing...';
    }

    if (fileIsUploading) {
      return 'Uploading...';
    }

    if (file?.sizeInBytes) {
      return formatFileSize(file.sizeInBytes);
    }

    if (fileType !== 'Unknown file') {
      return fileType;
    }

    return 'Unknown file type';
  };

  const isFileProcessingComplete = () => {
    return !fileIsUploading && !fileIsProcessing;
  };

  const removeChitFromFilesSelected = (file: FileType) => {
    setIsRemoving(true);
    setTimeout(() => {
      removeFileFromContexts(file?.id);
    }, 100);
  };

  return (
    <div
      data-testid="file-chit"
      className={classNames([
        'file-chit',
        {
          error: fileHasError,
          editable: isEditable,
          deleted: fileIsDeleted,
          'is-removing': isRemoving,
        },
      ])}
    >
      {isFileProcessingComplete() && <FileChitIcon type={extension} />}

      {fileIsUploading && (
        <div data-testid="file-is-uploading" className="file-icon uploading">
          <Progress
            type="circle"
            percent={30}
            size={25}
            trailColor="rgba(0, 0, 0, 0.0)"
            strokeColor="rgba(48, 209, 88, 1)"
            strokeWidth={20}
            showInfo={false}
          />
        </div>
      )}

      {fileIsProcessing && (
        <div data-testid="file-in-process" className="file-icon processing">
          <Spin size={'small'} />
        </div>
      )}

      <div className="file-information">
        <div className="file-name">
          {file.displayFileName || 'Missing File Name'}
        </div>
        <span className="file-description">{getStatusLabel(fileType)}</span>
      </div>

      {fileIsEditable && (
        <button
          data-testid="file-chit-close"
          className="file-chit-close"
          onClick={() => {
            removeChitFromFilesSelected(file);
          }}
        >
          <FileChitIconClose />
        </button>
      )}
    </div>
  );
};

export const FileChitIcon = ({ type }) => {
  const FileIconMapping = {
    pptx: FileChitIconPPTX,
    docx: FileChitIconDOCX,
    pdf: FileChitIconPDF,
    txt: FileChitIconTXT,
    xlsx: FileChitIconXLSX,
    index: FileChitIconIndex,
  };

  const FileIconComponent = FileIconMapping[type] || FileIconMapping.txt;
  const className = FileIconMapping[type] ? type : 'txt';

  return (
    <div data-testid="file-icon" className={`file-icon ${className}`}>
      <FileIconComponent />
    </div>
  );
};
