export const sortArrayByName = (array) => {
  return array.sort((a, b) => sortArrayByProperty(a, b, 'name'));
};

export const sortArrayByProperty = (a, b, property) => {
  let nameA = a[property]?.toLowerCase();
  let nameB = b[property]?.toLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};
