import { intervalToDuration, parseISO, isToday } from 'date-fns';

export const mapConversationHistoryToTimeBlocks = (conversations) => {
  const timeBlocks = {
    today: [] as any[],
    yesterday: [] as any[],
    lastWeek: [] as any[],
    older: [] as any[],
  };
  const now = new Date();

  conversations.forEach((conv) => {
    const end = parseISO(conv.datetimeCreated);
    const duration = intervalToDuration({
      start: now,
      end,
    });
    const { years, months, days } = duration;

    if (days !== undefined && months !== undefined && years !== undefined) {
      if (months > 0 || years > 0) {
        timeBlocks.older.push(conv);
      } else if (days <= 1 && months === 0 && years === 0) {
        if (isToday(end)) {
          timeBlocks.today.push(conv);
        } else {
          timeBlocks.yesterday.push(conv);
        }
      } else if (days <= 7 && months === 0 && years === 0) {
        timeBlocks.lastWeek.push(conv);
      }
    }
  });

  return [
    {
      id: 1,
      title: 'Today',
      history: [...timeBlocks.today],
    },
    {
      id: 2,
      title: 'Yesterday',
      history: [...timeBlocks.yesterday],
    },
    {
      id: 3,
      title: 'Previous 7 days',
      history: [...timeBlocks.lastWeek],
    },
    {
      id: 4,
      title: 'Older',
      history: [...timeBlocks.older],
    },
  ];
};

const historyMapper = { mapConversationHistoryToTimeBlocks };

export default historyMapper;
