import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'ui/atoms';
import ContextButton from 'ui/molecules/ContextButton';

import { ReactComponent as IconCaretDown } from './caret-down.svg';

import './gpt-model-selection.css';
import { loadUserLimits, UserStore } from 'stores/user';
import useUserModelLimits from 'services/hooks/useUserModelLimits';

export const buildGptModelDescription = (description, isAtLimit) => {
  if (!isAtLimit) {
    return description;
  }
  return (
    <>
      <span className="warning" data-testid="zero-prompts-remaining">
        0 prompts remaining this month
      </span>
      {description}
    </>
  );
};

const GptModelSelection = ({ setGptModel, gptModel, gptModels }) => {
  const [showModal, setShowModal] = useState(false);
  const [gptModelName, setGptModelName] = useState('');
  const exhaustedGptModels = UserStore.useState(
    (s) => s.exhaustedGptModels || [],
  );
  const userLimits = useUserModelLimits();

  useEffect(() => {
    if (showModal) {
      loadUserLimits();
    }
  }, [showModal]);

  useEffect(() => {
    if (!gptModels?.length) {
      return;
    }

    const currentModel = gptModels.find((item) => item.id === gptModel);

    const name = currentModel?.name;
    const displayName = currentModel?.displayName || name;

    if (!name) {
      setGptModel(gptModels[0]?.id);
      return;
    }

    setGptModelName(displayName);
  }, [exhaustedGptModels, gptModel, gptModels, setGptModel]);

  return (
    <div className="model-selection">
      <Button
        onClick={() => {
          setShowModal(true);
        }}
        type={'default'}
        data-testid="model-selection"
      >
        <div className="model-selection-text">
          {gptModelName}
          {userLimits && (
            <p className="user-limit-notice">
              {userLimits.promptsLeft} prompt
              {userLimits.promptsLeft > 1 ? 's' : ''} remaining
            </p>
          )}
        </div>
        <IconCaretDown />
      </Button>
      <Modal
        title="Select AI Model"
        open={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[]}
      >
        <div className="gpt-model-selection">
          <div className="gpt-model-list">
            {gptModels &&
              gptModels.length > 0 &&
              gptModels.map((model) => {
                return (
                  <div
                    className="gpt-model-item"
                    key={`gpt-selection-${model.id}`}
                  >
                    {model.modelType}
                    <ContextButton
                      isSelected={model.id === gptModel}
                      title={model.displayName}
                      text={buildGptModelDescription(
                        model.description,
                        exhaustedGptModels.includes(model.id),
                      )}
                      value={model.id}
                      disabled={
                        model.disabled || exhaustedGptModels.includes(model.id)
                      }
                      setSelected={(value) => {
                        setGptModel(value);
                        setShowModal(false);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GptModelSelection;
