import React from 'react';
import classnames from 'classnames';

import { ReactComponent as IconCircle } from './circle.svg';
import { ReactComponent as IconCircleCheck } from './circle-check.svg';

import './context-button.css';

const ContextButton = ({
  title,
  text,
  value,
  isSelected,
  setSelected,
  disabled = false,
}) => {
  const handleCheckboxChange = () => {
    if (disabled || !setSelected) {
      return;
    }

    return setSelected(value);
  };

  return (
    <div
      data-testid="context-button"
      className={classnames(
        'context-button',
        { selected: isSelected },
        { disabled: disabled },
      )}
      onClick={handleCheckboxChange}
    >
      <div>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
      {!disabled && <>{isSelected ? <IconCircleCheck /> : <IconCircle />}</>}
    </div>
  );
};

export default ContextButton;
