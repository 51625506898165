import React from 'react';
import { Button, Modal } from '../../atoms';

import FileUpload from './FileUpload';
import {
  FileUploadStore,
  deleteFile,
  resetStore as resetFileStore,
  saveMetadata,
} from 'stores/file-upload';
import {
  UserStore,
  getPersonalFiles,
  updateDocumentsRemaining,
} from 'stores/user';
import { setGlobalMessage } from 'stores/global-message';
import { FILE_UPLOAD_MONTHLY_LIMIT_INFINITE } from 'stores/metadata';

const FileUploadModal = ({ fileUploadVisible, setFileUploadVisible }) => {
  const isValid = FileUploadStore.useState((s) => s.isValid);
  const hasError = FileUploadStore.useState((s) => s.hasError);
  const fileId = FileUploadStore.useState((s) => s.fileId);
  const uploadComplete = FileUploadStore.useState((s) => s.uploadComplete);

  const [hasSaved, setHasSaved] = React.useState(false);

  const documentsUploaded = UserStore.useState((s) => s.documentsUploaded);
  const documentLimit = UserStore.useState((s) => s.documentLimit);

  return (
    <Modal
      title={
        <>
          Upload file
          <div className="tiny">{`${documentsUploaded}/${
            documentLimit === FILE_UPLOAD_MONTHLY_LIMIT_INFINITE
              ? '∞'
              : documentLimit
          } file quota`}</div>
        </>
      }
      open={fileUploadVisible}
      onOk={() => {
        setFileUploadVisible(false);
        resetFileStore();
      }}
      onCancel={() => {
        if (!hasSaved) {
          if (fileId) {
            deleteFile(fileId);
          }
        }
        setFileUploadVisible(false);
        resetFileStore();
      }}
      footer={[
        <Button
          key="fileupload_cancel"
          type="outline"
          onClick={() => {
            if (fileId) {
              deleteFile(fileId);
            }
            setFileUploadVisible(false);
            resetFileStore();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="fileupload_apply"
          type="default"
          className={`${isValid && uploadComplete ? 'active' : 'unavailable'}`}
          disabled={!isValid || hasError || !uploadComplete}
          onClick={() => {
            setHasSaved(true);
            saveMetadata();
            setFileUploadVisible(false);
            getPersonalFiles();
            resetFileStore();

            (async () => {
              let globalMessage = '';
              const fileLimitWarning = await updateDocumentsRemaining();
              if (fileLimitWarning) {
                globalMessage = `Thank you for taking the time to improve Goldie! ${fileLimitWarning}`;
              }
              if (globalMessage) {
                setGlobalMessage(globalMessage);
              }
            })();
          }}
        >
          Save File
        </Button>,
      ]}
    >
      <FileUpload />
    </Modal>
  );
};

export default FileUploadModal;
