import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  useMsal,
} from '@azure/msal-react';
import { useIdleTimer } from 'react-idle-timer';

import config from 'config';
import { ConfirmTimeout } from 'ui';
import MsalAuthenticationError from 'ui/molecules/MsalAuthenticationError';
import { Features } from 'stores/features';
import { UserStore, logoutAction, setUserHasLoaded } from 'stores/user';
import { initApiVersion } from 'stores/app-context';
import { IDLE_TIMEOUT, IDLE_TIMEOUT_CONFIRMATION } from 'stores/metadata';
import useIsFeatureEnabled from 'services/hooks/useIsFeatureEnabled';
import useAidaApi from 'services/hooks/useApiConfig';
import apiService from 'services/api';
import { logger } from 'services/logs/logger';

import Splash from './splash';

const SetAuthed = ({ children }) => {
  const { createApiConfig } = useAidaApi();
  const { instance } = useMsal();
  const accountInfo = instance.getAllAccounts();

  useEffect(() => {
    (async () => {
      setTimeout(() => {
        createApiConfig().then((apiConfig) => {
          setUserHasLoaded(true, apiConfig, accountInfo[0]);
          apiService.setApiConfig(apiConfig);
          initApiVersion();
        });
      }, 500);
    })();
  }, [createApiConfig, accountInfo]);
  return <>{children}</>;
};

const AgreedToTerms = ({ children }) => {
  let navigate = useNavigate();
  const agreedToTerms = UserStore.useState((s) => s.agreedToTerms);

  useEffect(() => {
    if (!agreedToTerms) {
      return navigate('/terms');
    }
  }, [agreedToTerms, navigate]);
  return <>{children}</>;
};

const ProtectedRoute = ({ children }) => {
  const { instance } = useMsal();
  const accountInfo = instance.getAllAccounts();
  const viewedSplash = UserStore.useState((s) => s.viewedSplash);

  const [confirmTimeoutOpen, setConfirmTimeoutOpen] = React.useState(false);
  const isIdleTimeoutActive = useIsFeatureEnabled(Features.IdleTimeout);

  async function logout() {
    logoutAction();
    const currentAccount = accountInfo[0];

    await instance.logoutRedirect({
      account: currentAccount,
    });
  }

  const { start, getRemainingTime, isIdle, isPrompted } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onPrompt: () => {
      setConfirmTimeoutOpen(true);
      logger.info('User has been idle for too long. Starting timeout.');
    },
    onIdle: () => {
      logger.info('User has timed out. Logging out.');
      logout();
    },
    debounce: 0,
    startManually: true,
    promptBeforeIdle: IDLE_TIMEOUT_CONFIRMATION,
  });

  (window as any).goldie = {
    getRemainingTime: getRemainingTime,
    idleCountdown: () => {
      logger.info((window as any).goldie.getRemainingTime());
      setInterval(() => {
        logger.info((window as any).goldie.getRemainingTime());
      }, 10000);
    },
    isIdle,
    isPrompted,
  };

  useEffect(() => {
    if (isIdleTimeoutActive) {
      logger.info('Starting idle timer');
      start();
    }
  }, [start, isIdleTimeoutActive]);

  if (!viewedSplash && !accountInfo[0]) {
    return <Splash></Splash>;
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        scopes: config.scopes,
        authority: `${config.microsoftLoginUrl}/${config.tenantId}`,
        redirectUri: window.location.origin,
      }}
      errorComponent={MsalAuthenticationError}
    >
      <AuthenticatedTemplate>
        <SetAuthed>
          <AgreedToTerms>
            {children}
            <ConfirmTimeout
              confirmTimeoutOpen={confirmTimeoutOpen}
              confirmTimeoutFunction={(confirmed) => {
                if (confirmed) {
                  start();
                  logger.info('User aborted timeout');
                  setConfirmTimeoutOpen(false);
                }
              }}
              confirmTimeoutCancel={logout}
            />
          </AgreedToTerms>
        </SetAuthed>
      </AuthenticatedTemplate>
    </MsalAuthenticationTemplate>
  );
};

export default ProtectedRoute;
