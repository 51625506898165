import { useEffect } from 'react';
import { StrykerLogo } from 'ui';

import './report.css';

function removeElementIfExists(id: string) {
  const hasFrame = document.getElementById(id);

  if (hasFrame) {
    hasFrame.remove();
  }
}

const Report = ({ formType }) => {
  useEffect(() => {
    setTimeout(() => {
      let showDialogFn;
      switch (formType.toLowerCase()) {
        case 'feedback':
          showDialogFn = (window as any).JIRA_COLLECTOR?.showCollectorDialog;
          break;
        case 'bug':
          showDialogFn = (window as any).JIRA_COLLECTOR
            ?.showCollectorDialogReportBug;
          break;
      }

      if (!showDialogFn) {
        return;
      }

      removeElementIfExists('atlwdg-frame');
      removeElementIfExists('atlwdg-blanket');
      removeElementIfExists('atlwdg-loading');
      showDialogFn();
    }, 500);
  }, [formType]);

  return (
    <div className="report-page" data-testid="report-page">
      <a href="/">
        <StrykerLogo />
      </a>
    </div>
  );
};

export default Report;
