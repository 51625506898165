import { Store } from 'pullstate';
import { mapConversationHistoryToTimeBlocks } from '../utils/history-mapper';
import ApiService from '../services/api';

type ConversationHistoryStoreType = {
  id: number;
  title: string;
  history: { id: number; title: string }[];
  hasError: boolean;
};

const initialState: {
  hasLoaded: boolean;
  history: ConversationHistoryStoreType[];
  hasError: boolean;
} = {
  hasLoaded: false,
  history: [],
  hasError: false,
};

export const ConversationHistoryStore = new Store(initialState);

export const resetStore = () => {
  ConversationHistoryStore.update((s) => {
    return initialState;
  });
};

export const loadConversationHistory = async () => {
  let conversations;
  try {
    conversations = await ApiService.getConversations();
  } catch (e) {
    return ConversationHistoryStore.update((s) => {
      return {
        ...s,
        hasLoaded: true,
        hasError: true,
      };
    });
  }
  if (!conversations) {
    return ConversationHistoryStore.update((s) => {
      return {
        ...s,
        hasLoaded: true,
      };
    });
  }
  return ConversationHistoryStore.update((s) => {
    return {
      hasLoaded: true,
      hasError: false,
      history: mapConversationHistoryToTimeBlocks(conversations),
    };
  });
};
