import { useEffect, useState } from 'react';
import { Input } from 'antd';
import { Button } from 'ui/atoms';
import DOMPurify from 'dompurify';
import classNames from 'classnames';

import {
  countStringNewLines,
  encodeStringToHtmlEntities,
} from 'utils/string-utils';
import { CurrentConversationStore } from 'stores/current-conversation';
import { QUESTION_LENGTH_MAX_IN_CHARS } from 'stores/metadata';
import { UserStore } from 'stores/user';
import { FileChitRow } from 'ui/molecules/FileChitRow';
import {
  diffCurrentPendingFiles,
  FileType,
  filterFilesInProgress,
  IndexType,
  sortFilesByLastUpdated,
} from 'utils/file-utils';

import { ReactComponent as QuestionInputIcon } from './submit-button.svg';

import './question-input.css';

const { TextArea } = Input;

type QuestionInputProps = {
  onSubmit: (question: string) => void;
  value?: string;
  onSelectFileClick?: any;
};

export const QuestionInput: React.FC<QuestionInputProps> = ({
  onSubmit,
  value = '',
  onSelectFileClick,
}) => {
  const [questionValue, setQuestionValue] = useState(value);
  const [textareaRows, setTextareaRows] = useState(1);
  const [isSubmitable, setIsSubmitable] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isMultiline, setIsMultiline] = useState(true);
  const isStreaming = CurrentConversationStore.useState((s) => s.isStreaming);
  const isConversationLoading = CurrentConversationStore.useState(
    (s) => s.isLoading,
  );
  const filesSelected = CurrentConversationStore.useState(
    (s) => s.fileContexts,
  );
  const indexesSelected = CurrentConversationStore.useState(
    (s) => s.indexContexts,
  );
  const personalFiles = UserStore.useState((s) => s.personalFiles);
  const personalIndexes = UserStore.useState((s) => s.personalIndexes);
  const allPromptsExhausted = UserStore.useState((s) => s.allPromptsExhausted);
  const [pendingFilesToApply, setPendingFilesToApply] = useState<FileType[]>(
    [],
  );
  const [pendingIndexesToApply, setPendingIndexesToApply] = useState<
    IndexType[]
  >([]);
  const [hasPendingFiles, setHasPendingFiles] = useState(false);

  useEffect(() => {
    setDisableSubmit(
      isStreaming || !isSubmitable || hasPendingFiles || allPromptsExhausted,
    );
  }, [isSubmitable, isStreaming, hasPendingFiles, allPromptsExhausted]);

  useEffect(() => {
    const charactersPerRow = 75;
    const questionLength = questionValue.length;
    const rows = Math.ceil(questionLength / charactersPerRow);
    const hasNewlines = questionValue.includes('\n');

    setTextareaRows(1);
    setIsSubmitable(false);
    setIsMultiline(false);

    if (questionLength) {
      setIsSubmitable(true);
    }

    if (questionLength >= QUESTION_LENGTH_MAX_IN_CHARS) {
      setQuestionValue(questionValue.slice(0, QUESTION_LENGTH_MAX_IN_CHARS));
    }

    if (hasNewlines) {
      const newLines = countStringNewLines(questionValue);
      setIsMultiline(true);
      setTextareaRows((prev) => prev + newLines);
    }

    if (rows > 1 && !hasNewlines) {
      setIsMultiline(true);
      setTextareaRows(rows);
    }
  }, [questionValue]);

  useEffect(() => {
    if (!isMultiline) {
      setTextareaRows(1);
    }
  }, [isMultiline]);

  useEffect(() => {
    if (!isConversationLoading) {
      return;
    }
    setQuestionValue('');
  }, [isConversationLoading]);

  useEffect(() => {
    const processingFiles = filterFilesInProgress(personalFiles);

    if (processingFiles.length && questionValue?.length > 1) {
      setIsSubmitable(false);
    }

    if (!processingFiles.length && questionValue?.length > 1) {
      setIsSubmitable(true);
    }

    const pending = diffCurrentPendingFiles(
      personalFiles,
      filesSelected,
    ) as FileType[];
    setPendingFilesToApply([...pending, ...processingFiles]);
    const pendingIndexes = diffCurrentPendingFiles(
      personalIndexes,
      indexesSelected,
      true,
    );

    setPendingIndexesToApply(pendingIndexes as IndexType[]);
    setHasPendingFiles(!!processingFiles.length && !!pendingIndexes.length);

    // eslint-disable-next-line
  }, [indexesSelected, personalIndexes, filesSelected, personalFiles]);

  const submit = () => {
    if (!questionValue?.length) {
      return;
    }

    const formatted = encodeStringToHtmlEntities(questionValue);
    onSubmit(DOMPurify.sanitize(formatted));
    setQuestionValue('');

    setPendingFilesToApply([]);
    setPendingIndexesToApply([]);
  };

  return (
    <div className="conversation-box">
      <QuestionInputFileChits
        pendingFilesToApply={pendingFilesToApply}
        pendingIndexesToApply={pendingIndexesToApply}
        onSelectFileClick={onSelectFileClick}
      />

      <div
        className={classNames([
          'conversation',
          {
            multiline: isMultiline,
            'has-file-chits':
              pendingFilesToApply.length || pendingIndexesToApply.length,
          },
        ])}
      >
        <TextArea
          placeholder="Ask me anything..."
          value={questionValue}
          rows={textareaRows}
          onChange={(e) => {
            setQuestionValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              !disableSubmit && submit();
            }
          }}
          data-testid="question-input-textarea"
        />
        <Button
          type="text"
          shape="circle"
          className={classNames(['', { '-disabled': disableSubmit }])}
          disabled={disableSubmit}
          onClick={submit}
          data-testid="question-input-button"
          icon={<QuestionInputIcon />}
        />
      </div>
      <span
        className={classNames(['input-character-limit'], {
          '-show': questionValue.length > 0,
        })}
        data-testid="character-limit-warning"
      >
        {questionValue.length}/{QUESTION_LENGTH_MAX_IN_CHARS}
      </span>
    </div>
  );
};

export const QuestionInputFileChits = ({
  pendingFilesToApply,
  pendingIndexesToApply,
  onSelectFileClick,
}) => {
  return (
    <div
      className={classNames([
        'conversation-file-chits',
        {
          'has-file-chits':
            pendingFilesToApply.length || pendingIndexesToApply.length,
        },
      ])}
    >
      <FileChitRow
        size="small"
        isEditable={true}
        files={sortFilesByLastUpdated(pendingFilesToApply)}
        indexes={pendingIndexesToApply}
        onSelectFileClick={onSelectFileClick}
      />
    </div>
  );
};
