import { UserStore } from 'stores/user';
import { FileChitRow } from 'ui/molecules/FileChitRow';

import './conversation-message-row.css';

const ConversationMessageRow = ({
  message,
  onSelectFileClick,
  associatedFiles,
  associatedIndexes,
}) => {
  const initials = UserStore.useState((s) => s.userInfo?.initials || '!!');
  return (
    <div className="message -user">
      <div className="initials">{initials}</div>
      <div className="message-content-wrapper">
        <FileChitRow
          onSelectFileClick={onSelectFileClick}
          files={associatedFiles}
          indexes={associatedIndexes}
        />
        <div
          data-testid="user-message"
          className="message-content"
          dangerouslySetInnerHTML={{ __html: message }}
        ></div>
      </div>
    </div>
  );
};

export default ConversationMessageRow;
