import React from 'react';

import { CheckboxMultiSelect } from 'ui/molecules/CheckboxMultiSelect';
import { CheckboxFileSelection } from 'ui/molecules';
import { EmptyResource } from 'ui/atoms';
import { ResourceSelectionType } from 'utils/file-utils';

type ResourceListProps = {
  setSelectedItems: (items: any[]) => void;
  selectedItemIds: string[];
  setError: (error: string) => void;
  availableToSelectItems: ResourceSelectionType[];
  title?: string;
  className?: string;
  showSelectedAll?: boolean;
  hideCheckbox?: boolean;
  type?: string;
};

const ResourceList: React.FC<ResourceListProps> = ({
  setSelectedItems,
  selectedItemIds,
  setError,
  availableToSelectItems,
  title,
  className,
  showSelectedAll = true,
  hideCheckbox = false,
  type,
}) => {
  if (!availableToSelectItems.length) {
    if (type === 'selected') {
      return <EmptyResource message="No files or indexes selected" />;
    }

    return <EmptyResource />;
  }

  return (
    <div data-testid="resource-list" className={className}>
      {showSelectedAll && availableToSelectItems.length > 0 && (
        <CheckboxMultiSelect
          label="Select all"
          isChecked={selectedItemIds.length === availableToSelectItems.length}
          isMixedSelection={
            selectedItemIds.length > 0 &&
            selectedItemIds.length !== availableToSelectItems.length
          }
          setSelected={(event) => {
            if (event.target.checked) {
              setSelectedItems(availableToSelectItems.map((file) => file.id));
            } else {
              setSelectedItems([]);
            }
          }}
        />
      )}
      {title && <h2>{title}</h2>}
      <ul className="file-list">
        {availableToSelectItems.map((file) => (
          <li
            className="file-list-item"
            key={`file-select-${file.id}`}
            data-testid={file.id}
          >
            <CheckboxFileSelection
              file={file}
              isChecked={file.isSelected || selectedItemIds.includes(file.id)}
              setSelected={(e) => {
                if (e.target.checked) {
                  setSelectedItems([...selectedItemIds, file.id]);
                } else {
                  setSelectedItems(
                    selectedItemIds.filter((id) => id !== file.id),
                  );
                }
                setError('');
              }}
              hideCheckbox={hideCheckbox}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ResourceList;
