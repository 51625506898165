import { useEffect } from 'react';
import { loadUserLimits, UserStore } from 'stores/user';
import {
  CurrentConversationStore,
  setGptModel,
} from 'stores/current-conversation';
import { AppContextStore, toggleSettingsMenuOpen } from 'stores/app-context';
import { SettingsMenuModal } from './settings-menu-modal';
import { MetadataStore } from 'stores/metadata';
import { GptModelSelection } from 'ui/molecules';

import './settings.css';

const Settings = ({ children, setTheme }) => {
  const userInitials = UserStore.useState((s) => s.userInfo?.initials);
  const gptModel = CurrentConversationStore.useState((s) => s.gptModel);
  const gptModels = MetadataStore.useState((s) => s.gptModels);
  const isSidebarCollapsed = AppContextStore.useState(
    (s) => s.isSidebarCollapsed,
  );
  const isSettingsMenuOpen = AppContextStore.useState(
    (s) => s.isSettingsMenuOpen,
  );

  useEffect(() => {
    if (isSettingsMenuOpen) {
      loadUserLimits();
    }
  }, [isSettingsMenuOpen]);

  return (
    <div
      className={`settings ${isSidebarCollapsed ? '-collapsed-sidebar' : ''}`}
      data-testid="settings"
    >
      {children}

      <GptModelSelection
        gptModel={gptModel}
        gptModels={gptModels}
        setGptModel={setGptModel}
      />

      <button
        data-testid="settings-menu-button"
        className="avatar"
        aria-label="View Settings"
        onClick={toggleSettingsMenuOpen}
      >
        {userInitials}
      </button>

      <SettingsMenuModal
        toggleSettingsMenu={isSettingsMenuOpen}
        setToggleSettingsMenu={toggleSettingsMenuOpen}
        setTheme={setTheme}
      />
    </div>
  );
};

export default Settings;
