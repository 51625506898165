import React from 'react';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import { formatDate } from 'utils/time-utils';

import {
  ResourceSelectionType,
  formatFileErrorMessages,
  formatFileTypeExtensions,
} from 'utils/file-utils';

import './checkbox-file-selection.css';

type CheckboxFileSelectionProps = {
  file?: ResourceSelectionType;
  header?: string;
  isChecked: boolean;
  setSelected: (e: any) => void;
  disabled?: boolean;
  hideCheckbox?: boolean;
};

const errorMessageMaps = {
  'File max token length exceeded': 'File contains too much text',
};

export const mapProcessingText = (status: string, comment: string) => {
  if (status === 'error' && !comment) {
    return 'Error with file processing';
  }

  if (status === 'error' && comment) {
    const mappedComment = errorMessageMaps[comment];

    if (mappedComment) {
      return mappedComment;
    }

    return comment;
  }

  if (status === 'rejected') {
    return comment;
  }

  if (status === 'deleted') {
    return 'File deleted';
  }

  if (status === 'deleting') {
    return 'Deleting...';
  }

  return 'File processing...';
};

export function getFileTypeForDisplay(filename: string): string {
  const [fileType] = formatFileTypeExtensions(filename);

  return fileType;
}

const CheckboxFileSelection: React.FC<CheckboxFileSelectionProps> = ({
  file = {} as ResourceSelectionType,
  header = null,
  isChecked,
  setSelected,
  disabled = false,
  hideCheckbox = false,
}) => {
  const {
    id,
    displayFileName,
    datetimeCreated,
    datetimeLastUpdated,
    processingStatus,
    processingFailureComment,
    sublabel,
    itemType,
  } = file;

  const statusHasError =
    processingStatus === 'error' || processingFailureComment;
  const statusIsDone = processingStatus === 'done';

  const hasValidDate = ({ datetimeCreated, datetimeLastUpdated }) => {
    return datetimeCreated || datetimeLastUpdated;
  };

  return (
    <div
      className={classNames('checkbox-file-selection', {
        disabled: disabled,
        'is-checked': isChecked,
        'file-available': statusIsDone,
        'file-unavailable': !statusIsDone,
        'is-errored': statusHasError,
        'is-deleted': processingStatus === 'deleted',
        'is-deleting': processingStatus === 'deleting',
      })}
    >
      {!hideCheckbox && (
        <Checkbox
          id={`checkbox_${id}`}
          disabled={disabled}
          checked={isChecked}
          onChange={(e) => {
            setSelected(e);
          }}
        />
      )}
      <label htmlFor={`checkbox_${id}`} className="label-text">
        {header && <div className="header">{header}</div>}
        <div className="primary">{displayFileName}</div>

        {itemType === 'file' && hasValidDate(file) && (
          <FileMetadata
            statusHasError={statusHasError}
            sublabel={sublabel}
            datetimeCreated={datetimeCreated}
          />
        )}

        {itemType === 'index' && hasValidDate(file) && (
          <IndexMetadata
            statusHasError={statusHasError}
            sublabel={sublabel}
            datetimeLastUpdated={datetimeLastUpdated}
          />
        )}
      </label>
    </div>
  );
};

const FileMetadata = ({ statusHasError, sublabel, datetimeCreated }) => {
  return (
    <div className="metadata">
      <div className="sublabel">
        {statusHasError && formatFileErrorMessages(sublabel, 'list')}
        {!statusHasError && sublabel}
      </div>
      <div className="date">{formatDate(datetimeCreated)}</div>
    </div>
  );
};

const IndexMetadata = ({ statusHasError, sublabel, datetimeLastUpdated }) => {
  return (
    <div className="metadata">
      <div className="sublabel">
        {statusHasError && formatFileErrorMessages(sublabel, 'list')}
        {!statusHasError && sublabel}
      </div>
      <div className="date">{formatDate(datetimeLastUpdated)}</div>
    </div>
  );
};

export default CheckboxFileSelection;
