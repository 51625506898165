import { useState } from 'react';
import classNames from 'classnames';

import { removeIndexFromContexts } from 'stores/current-conversation';
import { IndexType } from 'utils/file-utils';
import { pluralizeLabel } from 'utils/string-utils';
import { FileChitIcon } from 'ui/molecules/FileChit';

import { ReactComponent as FileChitIconClose } from 'ui/molecules/FileChit/file-chit-icons/icon-close.svg';

export const getIndexFileAndSiteSummaryLabel = ({ numFiles, numWebsites }) => {
  const hasFiles = Number(numFiles) > 0;
  const hasWebsites = Number(numWebsites) > 0;

  if (!hasFiles && !hasWebsites) {
    return '';
  }

  const websiteLabel = pluralizeLabel('website', numWebsites);
  const fileLabel = pluralizeLabel('file', numFiles);

  if (!hasFiles && hasWebsites) {
    return `${numWebsites} ${websiteLabel}`;
  }

  if (hasFiles && !hasWebsites) {
    return `${numFiles} ${fileLabel}`;
  }

  return `${numFiles} ${fileLabel}, ${numWebsites} ${websiteLabel}`;
};

export const IndexChit = ({ index, isEditable }) => {
  const fileIsEditable = isEditable;
  const [isRemoving, setIsRemoving] = useState(false);

  const removeChitFromSelected = (index: IndexType) => {
    setIsRemoving(true);
    setTimeout(() => {
      removeIndexFromContexts(index?.id);
    }, 100);
  };

  return (
    <div
      data-testid="file-chit"
      className={classNames([
        'file-chit',
        {
          editable: isEditable,
          'is-removing': isRemoving,
        },
      ])}
    >
      <FileChitIcon type={'index'} />

      <div className="file-information">
        <div className="file-name">
          {index.displayName || 'Missing Index Name'}
        </div>
        <span className="file-description">
          {getIndexFileAndSiteSummaryLabel(index)}
        </span>
      </div>

      {fileIsEditable && (
        <button
          data-testid="file-chit-close"
          className="file-chit-close"
          onClick={() => {
            removeChitFromSelected(index);
          }}
        >
          <FileChitIconClose />
        </button>
      )}
    </div>
  );
};
